@import 'helper/_fonts.scss';
@import 'helper/_variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

body {
  background: rgba(74,6,163,1);
  background: -moz-linear-gradient(-45deg, rgba(74,6,163,1) 0%, rgba(64,10,23,1) 97%, rgba(71,11,25,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(74,6,163,1)), color-stop(97%, rgba(64,10,23,1)), color-stop(100%, rgba(71,11,25,1)));
  background: -webkit-linear-gradient(-45deg, rgba(74,6,163,1) 0%, rgba(64,10,23,1) 97%, rgba(71,11,25,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(74,6,163,1) 0%, rgba(64,10,23,1) 97%, rgba(71,11,25,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(74,6,163,1) 0%, rgba(64,10,23,1) 97%, rgba(71,11,25,1) 100%);
  background: linear-gradient(135deg, rgba(74,6,163,1) 0%, rgba(64,10,23,1) 97%, rgba(71,11,25,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a06a3', endColorstr='#470b19', GradientType=1 );
  font-family: 'montserrat', sans-serif;
  color: #fff;
  box-sizing:  border-box;
  padding: 1em 0 0 2em;
  background-attachment: fixed;
}

h1 {
  color: aqua;
  font-size: 3em;
  width: 0.3em;
  &::selection {
    color: #d6404a;
  }
}

p {
  word-wrap: break-spaces;
  font-size: 3em;
  &::selection {
    color: #ffcc00;
  }
}

@media only screen and (min-width: 400px) {
  h1 {
    font-size: 4em;
  }
}
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 6em;
  }
}